@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.a-container {
  position: relative;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
}

.a-container .a-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 100px 0;
}

.a-container .a-card:nth-child(odd) {
  flex-direction: row;
}

.a-container .a-card:nth-child(even) {
  flex-direction: row-reverse;
  text-align: right;
}

.a-container .a-card .imgBx {
  position: relative;
  left: 25px;
  width: 500px;
  height: 400px;
  z-index: 1;
}

.a-container .a-card:nth-child(even) .imgBx {
  left: -25px;
}

.a-container .a-card .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.a-container .a-card .contentBx {
  position: relative;
  right: 25px;
  width: 500px;
  height: 400px;
  background: rgba(229, 203, 144, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 60px 20px 100px;
}

.a-container .a-card:nth-child(even) .contentBx {
  right: -25px;
  padding: 20px 100px 20px 60px;
}

.a-container .a-card .contentBx::before {
  content: "";
  position: absolute;
  top: -50px;
  bottom: -50px;
  left: 0;
  right: 0;
  background: rgba(229, 203, 144, 1);
  z-index: -1;
}

.a-container .a-card .contentBx h2 {
  font-size: 30px;
  color: #111;
}

.a-container .a-card .contentBx p {
  margin-top: 10px;
  color: #111;
}

.a-container .a-card .contentBx a {
  display: inline-block;
  margin-top: 15px;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  border: 1px solid #fff;
}

@media (max-width: 1200px) {
  .a-container .a-card {
    flex-direction: column !important;
    margin: 50px;
    text-align: center !important;
  }

  .a-container .a-card .imgBx {
    width: 100%;
    height: 250px;
    left: 0 !important;
  }

  .a-container .a-card .contentBx {
    width: 100%;
    height: auto;
    right: 0 !important;
    padding: 30px !important;
    text-align: center;
  }

  .a-container .a-card .contentBx::before {
    top: 0;
    bottom: 0;
  }

  .a-container .a-card:nth-child(even) .imgBx {
    left: 0px;
  }

  .a-container .a-card:nth-child(even) .contentBx {
    right: 0;
    padding: 30px;
  }
}
