.testimonial-section {
  /* background: linear-gradient(to bottom, #dd2476, #ff512f); */
  padding: 40px 0;
  margin: 40px 0;
  border-radius: 23px;
}

.inner-width {
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}
.testimonial-section {
  text-align: center;
  color: #ffffff;
  font-size: 24px;
}
.border {
  width: 100px;
  height: 3px;
  background: #333;
  margin: 40px auto;
}
.testimonial {
  background: #ffffff;
  padding: 40px;
  height: fit-content;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}
.test-info {
  display: flex;
  height: 60px;
  align-items: center;
}
.test-pic {
  width: 80px !important;
  height: 80px !important;
  border-radius: 50%;
  margin-right: 15px;
  border: 2px solid #0879fa;
}
.test-name {
  display: flex;
  flex-direction: column;
  margin-bottom: -10px;
  font-size: 12px;
  color: #333;
}
.test-name span {
  display: block;
  font-size: 16px;
  font-weight: 900;
  color: #3498db;
}
.testimonial p {
  font-size: 16px;
  /* font-family: ""; */
  line-height: 22px;
  letter-spacing: 1px;
  font-weight: 100;
  margin-top: 20px;
  color: #333;
}
.card {
  margin: auto;
}
.cards h1 {
  color: black !important;
}
.header {
  margin-top: 50px;
  background-color: black;
  margin-bottom: 0px;
  opacity: 0.7;
  width: 70%;
  margin-left: 15%;
}
.header h1 {
  color: white;
}

/* .backgroundImg {
  background-image: url("https://images.unsplash.com/photo-1584208124835-8a3d74ae211c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=417&q=80");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
} */
