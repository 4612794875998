.image-grid {
  width: 100%;
  padding: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* z-index: 10; */
}
.title h1 {
  color: black;
}

.img-wrap img {
  object-fit: cover;
  height: 350px;
  width: 350px;
  /* padding-left: 20px;
  padding-right: 20px; */
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  border-radius: 5px;
}

/* .cards__item__img:hover {
  transform: scale(1.1);
} */

.img-wrap img:hover {
  transform: scale(1.1);
}

.img-wrap {
  margin: 40px;
}

/* modal styles */
.backdrop {
  padding-top: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 90%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
  z-index: 100;
}

.galleryImg {
  background: white;
  box-shadow: 5px 5px 25px #888888, -5px -5px 25px #888888;
  object-fit: contain;
}

@media only screen and (max-width: 420px) {
  .img-wrap img {
    height: 280px;
    width: 280px;
  }
}
