* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Josefin Sans", sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url("/images/img-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url("/images/img-1.jpg");
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url("/images/img-8.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.socialIcon {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.socialIcon:hover {
  transform: scale(1.5);
}
/* .review_image {
  height: fit-content;
  width: 300px;
} */
.review {
  padding: 120px;
  object-fit: contain;
  height: 1000px;
  width: 1000px;
}
/* .lessons {
  background-color: white;
  height: max;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.card_lesson {
  height: 500px;
} */
.header {
  margin-top: 50px;
  background-color: black;
  margin-bottom: 100px;
  opacity: 0.7;
  width: 70%;
  margin-left: 15%;
}
.header h1 {
  color: white;
}

.footerBorder {
  border-right: 3px solid;
}

@media only screen and (max-width: 1200px) {
  .footerBorder {
    background: "red";
    border-bottom: 3px ridge;
    border-right: 0px;
  }

  .addressSpacing {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .footerIconSpacing {
    margin-bottom: 12px;
  }
}

.footerForm {
  min-width: 600px;
}

@media only screen and (max-width: 650px) {
  .footerForm {
    min-width: 450px;
  }
}

@media only screen and (max-width: 470px) {
  .footerForm {
    min-width: 350px;
  }
  .lessonSectionRight {
    padding-right: 10px;
  }
}

.bgImage {
  background-image: url("./assets/images/contact.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding-top: 40px;
}
.product_rating {
  display: flex;
}

.achievementCard {
  width: 28rem;
  overflow: hidden;
}

@media only screen and (max-width: 560px) {
  .achievementCard {
    width: 20rem;
  }
}

@media only screen and (max-width: 400px) {
  .achievementCard {
    width: 18rem;
  }
}

@media only screen and (max-width: 375px) {
  .achievementCard {
    width: 16rem;
  }
}

@media only screen and (max-width: 350px) {
  .achievementCard {
    width: 14rem;
  }
}
