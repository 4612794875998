.cards {
  padding: 4rem !important;
  background: #fff;
}

h1 {
  text-align: center !important;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative !important;
  margin: 50px 0 45px !important;
  width: 100%;
}
.cardImage {
  transition-duration: 0.3s !important;
  transition-property: all !important;
  transition-timing-function: ease-in-out !important;
  overflow: hidden;
}
.cardImage:hover {
  transform: scale(1.1) !important;
}
