@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Josefin Sans", sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url("/images/img-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url("/images/img-1.jpg");
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url("/images/img-8.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.socialIcon {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.socialIcon:hover {
  transform: scale(1.5);
}
/* .review_image {
  height: fit-content;
  width: 300px;
} */
.review {
  padding: 120px;
  object-fit: contain;
  height: 1000px;
  width: 1000px;
}
/* .lessons {
  background-color: white;
  height: max;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.card_lesson {
  height: 500px;
} */
.header {
  margin-top: 50px;
  background-color: black;
  margin-bottom: 100px;
  opacity: 0.7;
  width: 70%;
  margin-left: 15%;
}
.header h1 {
  color: white;
}

.footerBorder {
  border-right: 3px solid;
}

@media only screen and (max-width: 1200px) {
  .footerBorder {
    background: "red";
    border-bottom: 3px ridge;
    border-right: 0px;
  }

  .addressSpacing {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .footerIconSpacing {
    margin-bottom: 12px;
  }
}

.footerForm {
  min-width: 600px;
}

@media only screen and (max-width: 650px) {
  .footerForm {
    min-width: 450px;
  }
}

@media only screen and (max-width: 470px) {
  .footerForm {
    min-width: 350px;
  }
  .lessonSectionRight {
    padding-right: 10px;
  }
}

.bgImage {
  background-image: url(/static/media/contact.901f0fa4.jpeg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding-top: 40px;
}
.product_rating {
  display: flex;
}

.achievementCard {
  width: 28rem;
  overflow: hidden;
}

@media only screen and (max-width: 560px) {
  .achievementCard {
    width: 20rem;
  }
}

@media only screen and (max-width: 400px) {
  .achievementCard {
    width: 18rem;
  }
}

@media only screen and (max-width: 375px) {
  .achievementCard {
    width: 16rem;
  }
}

@media only screen and (max-width: 350px) {
  .achievementCard {
    width: 14rem;
  }
}

.navbar {
  background: linear-gradient(
    90deg,
    rgb(28, 27, 27) 0%,
    rgb(26, 23, 23) 100%
  ) !important;
  height: 80px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 1.2rem !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 999 !important;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.contact_button:hover {
  border: 1px solid white !important;
}

.navbar-logo {
  color: #fff !important;
  justify-self: start;
  margin-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  text-decoration: none !important;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  color: white;
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
  text-decoration: none;
}

.header_logo {
  width: 100px;
  object-fit: contain;
  margin: 0 20px;
  margin-top: 18px;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
.ftco-navbar-light {
  background: transparent !important;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  top: 30px;
}

:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-bottom: 12px;
}

.btn--primary {
  background-color: #fff;
  background-color: var(--primary);
  color: #242424;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}

.bg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background: url("https://images.unsplash.com/photo-1512733596533-7b00ccf8ebaf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=889&q=80https://images.unsplash.com/photo-1512733596533-7b00ccf8ebaf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=889&q=80")
    center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  object-fit: contain;
  background-attachment: fixed;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  /* font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif; */
  font-family: "Dancing Script";
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}
.successBtn {
  padding: 8px 20px;
  padding: 12px 26px;
  font-size: 21px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-bottom: 12px;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

/* /* .about {
  background-color: aliceblue;
  padding: 15px;
}
h1 {
  /* margin-top: 10px; */
/* margin-bottom: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 70px;
}
p {
  text-align: center;
  letter-spacing: 2px;
  font-size: medium;
  font-weight: 200;
}  */
.about_bg {
  background: white;
  padding: 10px;
}
.image_about {
  border: 1px solid white;
  margin-top: 10px;
  margin-left: 10px;
  object-fit: cover;
  height: 40%;
  /* width: 40%; */
}
.aboutPara {
  letter-spacing: 1px;
  font-size: 20px;
  padding-bottom: 10px;
}

.aboutButton {
  margin: 0 !important;
  padding: 0 !important;
}
.break {
  height: 30px;
}

/* ABOUT PAGE */

.aboutImage {
  background: url("https://images.unsplash.com/photo-1561101748-3f6b608c5039?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80")
    center center/cover no-repeat;
  height: 60vh;
  width: 100%;
  /* padding: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  object-fit: contain;
  background-attachment: fixed;
}
.aboutImage p,
h1 {
  color: white;
  font-size: 50px;
  font-weight: 100;
  margin-left: -15px;
}
/* .about_para_1 {
  text-align: left !important;
  padding: 10px;
  margin-top: 10%;
  background-color: #1c1919a6;
  height: fit-content;
}
.about_para_1 h1 {
  font-weight: 100;
  text-align: left !important;
  padding-left: 10px;
}
.about_para_1 p {
  letter-spacing: 1px;
  font-size: 17px;
  font-weight: 100;
  margin: auto;
}
.header2 {
  margin-top: 100px !important;
  font-size: 20px;
  opacity: 0.2;
}

.aboutImage2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about_para_2 {
  background-color: white;
  z-index: -1;
}
.teacherImage {
  height: 400px;
  width: 500px;
  padding: 15px;
  border-radius: 12px;
  border-color: 2px solid white;
} */
.portfolio-image-container {
  width: 100%;
  /* height: 500px; */
  overflow: hidden;
  margin: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.portfolio-image-container .teacher_img {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
  /* border: 3px solid #1b1919; */
  border-radius: 5px;
  align-self: center;
}

.portfolio-details {
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: transparent;
  text-align: center;
  padding: 10px;
  padding-top: 50%;
}

.portfolio-image-container:hover {
  box-shadow: 0 0 20px #74adc8;
}

.portfolio-image-container:hover .portfolio-details {
  background-color: #80808078;
  color: white;
}

.teacher_info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.teacher_info p {
  margin-top: 40px;
  margin-left: 30px;
  font-size: 20px;
  font-weight: 100;
}
.about1_bottom {
  background-color: white;
  font-size: 18px;
  font-weight: 100;
}
.break1 {
  height: 140px;
  width: 100%;
}
.about1_bottom {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.about1_bottom p {
  text-align: left;
  margin-left: 20px;
}
.header_about {
  font-size: 50px;
  font-weight: 200;
  background-color: rgba(0, 0, 0, 0.7);
}
@media only screen and (max-width: 768px) {
  .teacher_info {
    /* display: block; */
    /* flex-direction: row; */
    font-size: 20px;
  }
  .portfolio-image-container .teacher_img {
    object-fit: cover;
    height: 350px;
    width: 100%;
    /* zoom: 1; */
    border: 1px solid rgba(229, 203, 144, 1);
    /* display: none; */
    /* padding-left: 30px; */
  }

  .portfolio-details {
    padding-top: 25%;
  }
  .portfolio-details p {
    font-size: 15px;
    text-align: center;
    padding-right: 10px;
    padding-bottom: -10px;
  }
}

.testimonial-section {
  /* background: linear-gradient(to bottom, #dd2476, #ff512f); */
  padding: 40px 0;
  margin: 40px 0;
  border-radius: 23px;
}

.inner-width {
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}
.testimonial-section {
  text-align: center;
  color: #ffffff;
  font-size: 24px;
}
.border {
  width: 100px;
  height: 3px;
  background: #333;
  margin: 40px auto;
}
.testimonial {
  background: #ffffff;
  padding: 40px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}
.test-info {
  display: flex;
  height: 60px;
  align-items: center;
}
.test-pic {
  width: 80px !important;
  height: 80px !important;
  border-radius: 50%;
  margin-right: 15px;
  border: 2px solid #0879fa;
}
.test-name {
  display: flex;
  flex-direction: column;
  margin-bottom: -10px;
  font-size: 12px;
  color: #333;
}
.test-name span {
  display: block;
  font-size: 16px;
  font-weight: 900;
  color: #3498db;
}
.testimonial p {
  font-size: 16px;
  /* font-family: ""; */
  line-height: 22px;
  letter-spacing: 1px;
  font-weight: 100;
  margin-top: 20px;
  color: #333;
}
.card {
  margin: auto;
}
.cards h1 {
  color: black !important;
}
.header {
  margin-top: 50px;
  background-color: black;
  margin-bottom: 0px;
  opacity: 0.7;
  width: 70%;
  margin-left: 15%;
}
.header h1 {
  color: white;
}

/* .backgroundImg {
  background-image: url("https://images.unsplash.com/photo-1584208124835-8a3d74ae211c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=417&q=80");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
} */

.cards {
  padding: 4rem !important;
  background: #fff;
}

h1 {
  text-align: center !important;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative !important;
  margin: 50px 0 45px !important;
  width: 100%;
}
.cardImage {
  transition-duration: 0.3s !important;
  transition-property: all !important;
  transition-timing-function: ease-in-out !important;
  overflow: hidden;
}
.cardImage:hover {
  transform: scale(1.1) !important;
}

.social {
  position: fixed;
  top: 25%;
  right: 0;
  line-height: 0.5;
  z-index: 10;
  background-color: #1a1818;
  border-radius: 5px;
}

.social .social-btn {
  display: block;
  padding: 14px 13px;
  color: #ffffff;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-in-out;
}
.social .social-img-Ig {
  /* padding: 2px; */
  font-size: 25px;
}
.social .social-btn:hover {
  transform: scale(1.5);
}

.social .social-img {
  width: 19px;
}

/* .facebook {
  background-color: #3b5998;
}

.facebook:hover {
  background-color: #2d4373;
}

.linkedin {
  background-color: #0077b5;
}

.linkedin:hover {
  background-color: #00121c;
}

.twitter {
  background-color: #83c3f3;
}

.twitter:hover {
  background-color: #55acee;
}

.gmail {
  background-color: #dd4b39;
}

.gmail:hover {
  background-color: #c23321;
}

.whatsapp {
  background-color: #6ba92f;
}

.whatsapp:hover {
  background-color: #528124;
}

.mail {
  background-color: #ed4926;
}

.mail:hover {
  background-color: #cf3311;
} */

.lessonImage {
  background: url("https://images.unsplash.com/photo-1578410170179-bb10594ed395?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80")
    center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  /* padding: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  object-fit: contain;
  background-attachment: fixed;
}
.lesson1_para {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  width: 70%;
  margin-top: 0px;
  padding-left: 5%;
  padding-right: 5%;
  letter-spacing: 1px;
  font-size: 19px;
}
.lessonsSection {
  background-color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  color: black;
  text-align: left;
  padding-left: 20px;
}
.lessonsSection h2 {
  color: black;
  padding: 15px;
}
.list {
  padding-left: 20px;
  padding-bottom: 20px;
}
.list li {
  font-size: 17px;
  letter-spacing: 1px;
}

/* lessons right section */
.lessonSectionRight {
  background-color: #c07f1df5;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  color: white;
  text-align: left;
  padding-right: 50px;
  padding-left: 25px;
}
.lessonSectionRight h2 {
  padding-top: 10px;
}
.lessonSectionRight p {
  font-size: 17px;
  letter-spacing: 1px;
  padding-bottom: 20px;
}

/* SYLLABUS */
.syllabus {
  background-color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
}
.syllabusImg {
  object-fit: contain;
}
.syllabusLeft {
  align-self: center;
}
.syllabusLeft p {
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 100;
  padding-left: 10px;
}

@media only screen and (max-width: 768px) {
  .syllabus {
    display: block;
  }
  .syllabusImg {
    object-fit: contain;
    height: 300px;
    width: 100%;
  }
  .lessonSectionRight {
    padding-right: 10px;
  }
  .lessonsSection {
    padding-right: 25px;
  }
}

.syllabusDescription {
  display: flex;
  flex-direction: column;
}

.achievementBg {
  background-image: url("https://images.unsplash.com/photo-1598547692361-e19190decfde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80");
  min-height: 50vh;
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom;
  position: relative;
}

.gap {
  background-image: url("https://images.unsplash.com/photo-1520523839897-bd0b52f945a0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding-top: 40px;
  height: 150px;
}
.gap h1 {
  color: white;
  background-color: black;
  opacity: 0.8;
  margin-bottom: 20px;
  padding-left: 10%;
  padding-right: 10%;
}

.BgDepth {
  /* background-color: rgb(188, 154, 77, 0.6); */
  min-height: 90vh;
  color: rgb(255, 255, 255);
}

.achievementHeader {
  /* font-family: "Sansita Swashed", cursive; */
  font-size: 80px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: black;
  opacity: 0.8;
}

@media only screen and (max-width: 620px) {
  .achievementHeader {
    font-size: 75px;
  }
}

@media only screen and (max-width: 470px) {
  .achievementHeader {
    font-size: 55px;
  }
}

@media only screen and (max-width: 360px) {
  .achievementHeader {
    font-size: 40px;
  }
}

/* gallery */

.gallery-waraper {
  display: inline;
  /* flex-direction: row;
  flex-wrap: nowrap; */
}
.img {
  height: 300px;
  width: 300px;
}

.a-container {
  position: relative;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
}

.a-container .a-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 100px 0;
}

.a-container .a-card:nth-child(odd) {
  flex-direction: row;
}

.a-container .a-card:nth-child(even) {
  flex-direction: row-reverse;
  text-align: right;
}

.a-container .a-card .imgBx {
  position: relative;
  left: 25px;
  width: 500px;
  height: 400px;
  z-index: 1;
}

.a-container .a-card:nth-child(even) .imgBx {
  left: -25px;
}

.a-container .a-card .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.a-container .a-card .contentBx {
  position: relative;
  right: 25px;
  width: 500px;
  height: 400px;
  background: rgba(229, 203, 144, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 60px 20px 100px;
}

.a-container .a-card:nth-child(even) .contentBx {
  right: -25px;
  padding: 20px 100px 20px 60px;
}

.a-container .a-card .contentBx::before {
  content: "";
  position: absolute;
  top: -50px;
  bottom: -50px;
  left: 0;
  right: 0;
  background: rgba(229, 203, 144, 1);
  z-index: -1;
}

.a-container .a-card .contentBx h2 {
  font-size: 30px;
  color: #111;
}

.a-container .a-card .contentBx p {
  margin-top: 10px;
  color: #111;
}

.a-container .a-card .contentBx a {
  display: inline-block;
  margin-top: 15px;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  border: 1px solid #fff;
}

@media (max-width: 1200px) {
  .a-container .a-card {
    flex-direction: column !important;
    margin: 50px;
    text-align: center !important;
  }

  .a-container .a-card .imgBx {
    width: 100%;
    height: 250px;
    left: 0 !important;
  }

  .a-container .a-card .contentBx {
    width: 100%;
    height: auto;
    right: 0 !important;
    padding: 30px !important;
    text-align: center;
  }

  .a-container .a-card .contentBx::before {
    top: 0;
    bottom: 0;
  }

  .a-container .a-card:nth-child(even) .imgBx {
    left: 0px;
  }

  .a-container .a-card:nth-child(even) .contentBx {
    right: 0;
    padding: 30px;
  }
}

.image-grid {
  width: 100%;
  padding: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* z-index: 10; */
}
.title h1 {
  color: black;
}

.img-wrap img {
  object-fit: cover;
  height: 350px;
  width: 350px;
  /* padding-left: 20px;
  padding-right: 20px; */
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  border-radius: 5px;
}

/* .cards__item__img:hover {
  transform: scale(1.1);
} */

.img-wrap img:hover {
  transform: scale(1.1);
}

.img-wrap {
  margin: 40px;
}

/* modal styles */
.backdrop {
  padding-top: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 90%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
  z-index: 100;
}

.galleryImg {
  background: white;
  box-shadow: 5px 5px 25px #888888, -5px -5px 25px #888888;
  object-fit: contain;
}

@media only screen and (max-width: 420px) {
  .img-wrap img {
    height: 280px;
    width: 280px;
  }
}

