.achievementBg {
  background-image: url("https://images.unsplash.com/photo-1598547692361-e19190decfde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80");
  min-height: 50vh;
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom;
  position: relative;
}

.gap {
  background-image: url("https://images.unsplash.com/photo-1520523839897-bd0b52f945a0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding-top: 40px;
  height: 150px;
}
.gap h1 {
  color: white;
  background-color: black;
  opacity: 0.8;
  margin-bottom: 20px;
  padding-left: 10%;
  padding-right: 10%;
}

.BgDepth {
  /* background-color: rgb(188, 154, 77, 0.6); */
  min-height: 90vh;
  color: rgb(255, 255, 255);
}

.achievementHeader {
  /* font-family: "Sansita Swashed", cursive; */
  font-size: 80px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: black;
  opacity: 0.8;
}

@media only screen and (max-width: 620px) {
  .achievementHeader {
    font-size: 75px;
  }
}

@media only screen and (max-width: 470px) {
  .achievementHeader {
    font-size: 55px;
  }
}

@media only screen and (max-width: 360px) {
  .achievementHeader {
    font-size: 40px;
  }
}

/* gallery */

.gallery-waraper {
  display: inline;
  /* flex-direction: row;
  flex-wrap: nowrap; */
}
.img {
  height: 300px;
  width: 300px;
}
